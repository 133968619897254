$imagesRootURL: "/images"; $fontsRootURL: "/fonts";
@charset "UTF-8";

@font-face {
  font-family: "icons";
  src:url( "#{ $fontsRootURL }/icons/vpins.eot" );
  src:url( "#{ $fontsRootURL }/icons/vpins.eot?#iefix" ) format("embedded-opentype"),
    url( "#{ $fontsRootURL }/icons/vpins.woff" ) format("woff"),
    url( "#{ $fontsRootURL }/icons/vpins.ttf" ) format("truetype"),
    url( "#{ $fontsRootURL }/icons/vpins.svg#icons" ) format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "icons" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"],
[class*=" icon-"] {
  height: 1em;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "icons" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1em;
  height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

$iconsList: (
  "add": "\61",
  "add-property": "\62",
  "amenities": "\63",
  "arrow-down": "\64",
  "arrow-left": "\65",
  "arrow-right": "\66",
  "arrow-up": "\67",
  "bath": "\68",
  "bedrooms": "\69",
  "billing": "\6a",
  "cal": "\6b",
  "check": "\6c",
  "comps": "\6d",
  "cross": "\6e",
  "dashboard": "\6f",
  "date-range-icon": "\70",
  "decrease-big": "\71",
  "decrease-small": "\72",
  "edit": "\73",
  "entire-home": "\74",
  "fav-off": "\75",
  "fav-onn": "\76",
  "filter": "\77",
  "hamburger": "\78",
  "increase-big": "\79",
  "increase-small": "\7a",
  "information": "\41",
  "location": "\42",
  "map": "\43",
  "my-listing": "\44",
  "notifications": "\45",
  "password": "\46",
  "price-correction": "\47",
  "price-drop": "\48",
  "private-room": "\49",
  "profile": "\4a",
  "property": "\4b",
  "search": "\4c",
  "shared-room": "\4d",
  "sleeps": "\4e",
  "sorting": "\4f",
  "star": "\50",
  "subscription": "\51",
  "warning": "\52",
  "info-hover": "\53",
);

@each $icon, $char in $iconsList {
  .icon-#{ $icon }:before {
    content: $char;
  }
}
