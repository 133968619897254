$imagesRootURL: "/images"; $fontsRootURL: "/fonts";
.vpinsight__logo-image {
    width: 43px;
    height: 24px;
    background-image: url( "/images/logos/logo.svg" );

    &__big {
        width: 60px;
        height: 33px;
        background-size: cover;
        flex-shrink: 0;
    }
}

.vpinsight__logo {
    max-width: 190px;

    &__main {
        max-width: initial;
    }
}

.vpinsight__logo,
.vpinsight__logo:hover,
.vpinsight__logo:visited {
    color: #D9DDE0;
}

.vpinsight__logo_collapsed {
    max-width: 43px;
}

.vpinsight__beta {
    margin-top: -16px;
    margin-right: -6px;
}

