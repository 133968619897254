$imagesRootURL: "/images"; $fontsRootURL: "/fonts";
@tailwind preflight;
@tailwind components;

// @import "./mics/iconfont";
// @import "./misc/montserrat";

@import "./components/Sidebar";

body {
    font-family: 'Montserrat', sans-serif;
    font-feature-settings: normal;
    font-variant: normal;

    overflow: hidden;
}

a {
    color: #1d6fdc;
    text-decoration: none;

    &:hover {
        color: #5494E8
    }
}

.react-tooltip-lite {
    background: #fff;
    box-shadow: 0px 0px 6px 3px rgba( 217, 221, 224, 0.3 );
    color: #2d3039;
    font-size: 14px;
    text-align: center
}

.react-tooltip-lite-arrow {
    border-color: #fff;
}

.vpinsight__scroll-bottom-gradient {
    position: absolute;
}

.bg-bottom-gradient {
    background: linear-gradient( rgba( 255,255,255,0 ), #fff );
    transition: opacity .2s ease;
}

.vpinsight__main {
    padding-top: 7.125rem;
}

@media (max-width: 768px) {
    .vpinsight__main {
        padding-top: 4.625rem;
    }
}

.vpinsight__property-selector-dropdown {
    .scrollbar-container {
        height: 300px !important;
    }
}

@tailwind utilities;
