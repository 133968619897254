@font-face {
  font-family: "icons";
  src: url("/fonts/icons/vpins.eot");
  src: url("/fonts/icons/vpins.eot?#iefix") format("embedded-opentype"), url("/fonts/icons/vpins.woff") format("woff"), url("/fonts/icons/vpins.ttf") format("truetype"), url("/fonts/icons/vpins.svg#icons") format("svg");
  font-weight: normal;
  font-style: normal; }

[data-icon]:before {
  font-family: "icons" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

[class^="icon-"],
[class*=" icon-"] {
  height: 1em; }

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "icons" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1em;
  height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-add:before {
  content: "a"; }

.icon-add-property:before {
  content: "b"; }

.icon-amenities:before {
  content: "c"; }

.icon-arrow-down:before {
  content: "d"; }

.icon-arrow-left:before {
  content: "e"; }

.icon-arrow-right:before {
  content: "f"; }

.icon-arrow-up:before {
  content: "g"; }

.icon-bath:before {
  content: "h"; }

.icon-bedrooms:before {
  content: "i"; }

.icon-billing:before {
  content: "j"; }

.icon-cal:before {
  content: "k"; }

.icon-check:before {
  content: "l"; }

.icon-comps:before {
  content: "m"; }

.icon-cross:before {
  content: "n"; }

.icon-dashboard:before {
  content: "o"; }

.icon-date-range-icon:before {
  content: "p"; }

.icon-decrease-big:before {
  content: "q"; }

.icon-decrease-small:before {
  content: "r"; }

.icon-edit:before {
  content: "s"; }

.icon-entire-home:before {
  content: "t"; }

.icon-fav-off:before {
  content: "u"; }

.icon-fav-onn:before {
  content: "v"; }

.icon-filter:before {
  content: "w"; }

.icon-hamburger:before {
  content: "x"; }

.icon-increase-big:before {
  content: "y"; }

.icon-increase-small:before {
  content: "z"; }

.icon-information:before {
  content: "A"; }

.icon-location:before {
  content: "B"; }

.icon-map:before {
  content: "C"; }

.icon-my-listing:before {
  content: "D"; }

.icon-notifications:before {
  content: "E"; }

.icon-password:before {
  content: "F"; }

.icon-price-correction:before {
  content: "G"; }

.icon-price-drop:before {
  content: "H"; }

.icon-private-room:before {
  content: "I"; }

.icon-profile:before {
  content: "J"; }

.icon-property:before {
  content: "K"; }

.icon-search:before {
  content: "L"; }

.icon-shared-room:before {
  content: "M"; }

.icon-sleeps:before {
  content: "N"; }

.icon-sorting:before {
  content: "O"; }

.icon-star:before {
  content: "P"; }

.icon-subscription:before {
  content: "Q"; }

.icon-warning:before {
  content: "R"; }

.icon-info-hover:before {
  content: "S"; }
