$imagesRootURL: "/images"; $fontsRootURL: "/fonts";



@media (min-width: 768px) {
    .vpinsight__topbar {
        padding-left: #{ 260px + 30px };
        padding-right: 30px;
    }

    .vpinsight__topbar_collapsed {
        padding-left: #{ 100px + 30px };
    }
}