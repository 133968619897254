$imagesRootURL: "/images"; $fontsRootURL: "/fonts";
@keyframes animate-circle {
	0% {
		transform: rotate( 0deg );
	}
	100% {
		transform: rotate( 360deg );
	}
}

.vpinsight__spinner {
	width: 90px;
	height: 90px;

	background-color: rgba( #1D6FDC, 0.2 );

	animation: animate-circle 10s linear infinite;
}

.vpinsight__spinner-dot {

	// background-color: rgba( #1D6FDC, 0.1 );

	height: 30%;
	width: 8px;

	top: 20%;
	left: 50%;
	margin-left: -4px;

	transform-origin: 50% 100%;

	&:after {
		content: " ";
		display: block;
		width: 8px;
		height: 8px;

		border-radius: 50%;

		transform: scale( 0.5 );

		background-color: #1D6FDC;
	}
}

@for $i from 8 through 1 {
	@keyframes animate-dot-#{$i} {
		0% {
			transform: scale( 0.5 );
		}
		50% {
			transform: scale( 1.5 );
		}
		100% {
			transform: scale( 0.5 );
		}
	}

	.vpinsight__spinner-dot:nth-child(#{$i}) {
		transform: rotate( 45deg * $i );

		&:after {
			animation: animate-dot-#{$i} 1.5s linear infinite;
			animation-delay: -( 9 - $i ) * 1.5s / 8;
		}
	}
}

